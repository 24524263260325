@charset "UTF-8";
* {
  font-family: "Noto Sans", "Roboto", sans-serif; }

@font-face {
  font-family: "Roboto";
  unicode-range: U+0041-005A, U+0061-007A, U+0030-0039, U+0020-002F, U+003A-0040, U+005B-0060, U+007B-007E;
  font-style: normal; }

html, body, div, span, applet, object, iframe,
p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  letter-spacing: -0.02em;
  font-size: 15px; }
  @media (max-width: 768px) {
    html, body, div, span, applet, object, iframe,
    p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
      font-size: 13px; } }

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  border: 0;
  text-decoration: none;
  letter-spacing: -0.02em; }

em {
  font-style: italic; }

strong {
  font-weight: bold; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

html, body, a {
  line-height: 1;
  overflow-x: auto;
  color: #3d3d3d; }
  @media (max-width: 768px) {
    html, body, a {
      overflow-x: hidden; } }

a {
  overflow: hidden; }

/* input 기본 스타일 초기화 */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  box-sizing: border-box; }
  .mobile select {
    padding: 0 2px;
    width: initial; }

input[type=text] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  box-sizing: border-box;
  padding: 0 8px;
  width: 160px; }
  .mobile input[type=text] {
    padding: 0 2px;
    width: initial; }

input[type=password] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
  box-sizing: border-box;
  padding: 0 8px;
  width: 160px; }
  .mobile input[type=password] {
    padding: 0 2px;
    width: initial; }

/* IE10 이상에서 input box 에 추가된 지우기 버튼 제거 */
input::-ms-clear {
  display: none; }

/* input type number 에서 화살표 제거 */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

/* Select box 스타일 초기화 */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

input[type="checkbox"] {
  vertical-align: text-top; }

/* IE 에서 Select box 화살표 제거 */
select::-ms-expand {
  display: none; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

.pc_layout {
  position: relative;
  width: 1024px;
  padding: 0 10px;
  margin: 0 auto;
  box-sizing: border-box; }
  .mobile .pc_layout {
    width: 100%;
    min-width: 320px; }

.table {
  display: table;
  table-layout: fixed;
  border-collapse: collapse;
  width: 100%;
  font-size: 13px;
  text-align: center; }
  .table .thead {
    display: table-header-group;
    border-top: 1px solid #54565c; }
    .table .thead .tr {
      border-bottom: 2px solid #eee; }
  .table .tbody {
    display: table-row-group;
    border-bottom: 1px solid #54565c; }
    .table .tbody .tr {
      border-bottom: 1px solid #eee; }
      .table .tbody .tr:last-child {
        border: 0; }
  .table .tr {
    display: table-row; }
  .table .th {
    display: table-cell;
    padding: 8px;
    font-weight: bold; }
  .table .td {
    display: table-cell;
    vertical-align: middle;
    padding: 8px 0; }

.input-form-container label {
  color: #333;
  display: block;
  margin-bottom: 4px;
  font-weight: bold;
  font-size: 13px; }

.input-form-container input {
  box-sizing: border-box;
  width: 100%;
  height: 42px;
  line-height: 2.75em;
  font-size: 13px;
  text-indent: 6px;
  border: 1px solid #c2c9cf; }
  .mobile .input-form-container input {
    width: 100%; }

.SingleDatePicker {
  width: 100%; }
  .SingleDatePicker .SingleDatePickerInput {
    width: 100%; }
    .SingleDatePicker .SingleDatePickerInput.SingleDatePickerInput__withBorder {
      border: 0; }
    .SingleDatePicker .SingleDatePickerInput .DateInput {
      width: 100%;
      font-size: 13px; }
      .SingleDatePicker .SingleDatePickerInput .DateInput > input {
        font-size: 13px;
        font-weight: 500; }
      .mobile .SingleDatePicker .SingleDatePickerInput .DateInput {
        width: inherit; }
    .SingleDatePicker .SingleDatePickerInput .DateInput_fang {
      top: 35px !important; }
    .SingleDatePicker .SingleDatePickerInput .SingleDatePicker_picker {
      top: 45px !important; }

.toast-success, .toast-error {
  opacity: .91;
  min-height: 52px; }
  .toast-success .toast-error-body, .toast-success .toast-success-body, .toast-error .toast-error-body, .toast-error .toast-success-body {
    color: #fff;
    font-weight: 500;
    letter-spacing: .7px;
    padding: 0 16px;
    font-weight: bold; }

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

.zoomIn {
  animation-name: zoomIn; }

@keyframes zoomOut {
  from {
    opacity: 1; }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3); }
  to {
    opacity: 0; } }

.zoomOut {
  animation-name: zoomOut; }

/* Not needed with the cssTransition helper */
.animate {
  animation-duration: 800ms; }

.text-center {
  text-align: center; }

.pc_visible {
  display: block; }
  @media (max-width: 1200px) {
    .pc_visible {
      display: none !important; } }

.mobile_visible {
  display: none; }
  @media (max-width: 1200px) {
    .mobile_visible {
      display: block !important; } }

/* components */
.header {
  z-index: 10;
  width: 100%;
  height: 114px;
  background: white;
  padding-bottom: 25px; }
  .header .header-wrap {
    width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
    display: flex; }
  .header .logo img {
    width: 141px;
    margin-top: 27px; }
  .header .nav-bar {
    display: none; }
  .header .header_inner {
    display: flex;
    font-weight: bold;
    font-size: 15px;
    align-items: flex-end; }
    .header .header_inner ul {
      display: flex; }
    .header .header_inner img {
      height: 12px; }
    .header .header_inner .gnb li {
      margin-right: 35px;
      height: 20px; }
    .header .header_inner .gnb a {
      display: block;
      height: 17px; }
    .header .header_inner .gnb .active a {
      color: #235d95;
      border-bottom: 3px solid #235d95; }
    .header .header_inner .bar {
      margin-left: 15px;
      margin-right: 50px;
      margin-bottom: 7px; }
    .header .header_inner .language li {
      margin-right: 20px;
      cursor: pointer; }
    .header .header_inner .language a {
      display: block;
      height: 17px; }
    .header .header_inner .language img {
      margin-left: 6px; }
    .header .header_inner .language .active a {
      color: #2886b9;
      border-bottom: 2px solid #235d95; }

.main .header {
  background: transparent;
  position: absolute;
  width: 100%; }
  .main .header .logo img {
    height: 121px;
    width: auto;
    margin-top: 46px; }
    @media (max-width: 1200px) {
      .main .header .logo img {
        height: 61px;
        width: auto;
        margin-top: 35px; } }

.main .header-wrap {
  max-width: 1640px;
  width: 100%; }

.main .header_inner {
  margin-top: 49px;
  align-items: normal; }
  .main .header_inner .gnb li a {
    color: white;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.4); }
  .main .header_inner .gnb .active a {
    color: #235d95;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
    border-bottom: 3px solid #235d95; }
  .main .header_inner .language a {
    text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
    color: white; }
  .main .header_inner .language .active a {
    color: #19d9cc;
    text-shadow: 1px 1px rgba(0, 0, 0, 0.4);
    border-bottom: 2px solid #19d9cc; }

@media (max-width: 1200px) {
  .header {
    background: transparent;
    position: absolute;
    width: 100%; }
    .header .logo img {
      height: 61px;
      width: auto;
      margin-top: 35px; }
    .header .nav-bar {
      display: block;
      margin-top: 35px; }
    .header .header-wrap {
      width: auto;
      padding: 0 30px; }
    .header .header_inner {
      display: none; } }

#mobile_nav {
  width: 100vw;
  height: 100vh;
  background: #204580;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s cubic-bezier(0, 0.52, 0, 1);
  overflow: hidden;
  z-index: 1000; }
  #mobile_nav .mobile_gnb {
    position: relative;
    width: 100%;
    background: #204580;
    height: 100%;
    transition: transform .3s;
    border-right: 1px solid #d7dbe6;
    overflow: auto;
    z-index: 1001; }
    #mobile_nav .mobile_gnb .nav-top {
      padding: 35px 30px 40px 30px;
      display: flex;
      justify-content: space-between; }
      #mobile_nav .mobile_gnb .nav-top .logo {
        height: 61px; }
      #mobile_nav .mobile_gnb .nav-top .close {
        width: 16px;
        height: 16px; }
    #mobile_nav .mobile_gnb ul.nav-list {
      background: #204580;
      padding: 8px 0 0; }
      #mobile_nav .mobile_gnb ul.nav-list > li {
        padding: 15px 24px;
        border-top: 1px solid #396cbf; }
        #mobile_nav .mobile_gnb ul.nav-list > li:last-child {
          border-bottom: 1px solid #396cbf; }
        #mobile_nav .mobile_gnb ul.nav-list > li a {
          font-size: 15px;
          color: white;
          font-weight: bold; }
        #mobile_nav .mobile_gnb ul.nav-list > li svg {
          float: right;
          font-size: 20px;
          margin-top: -4px; }
    #mobile_nav .mobile_gnb ul.sub-nav-list {
      margin-top: 4px; }
      #mobile_nav .mobile_gnb ul.sub-nav-list > li {
        padding: 25px 25px 6px 0; }
        #mobile_nav .mobile_gnb ul.sub-nav-list > li:last-child {
          padding-bottom: 0; }
        #mobile_nav .mobile_gnb ul.sub-nav-list > li a {
          font-size: 13px;
          color: white;
          font-weight: normal; }
    #mobile_nav .mobile_gnb .language {
      padding: 15px 24px;
      display: flex; }
      #mobile_nav .mobile_gnb .language > li a {
        font-size: 13px;
        color: white;
        font-weight: normal; }
      #mobile_nav .mobile_gnb .language > li:last-child {
        margin-left: 10px; }
      #mobile_nav .mobile_gnb .language .active a {
        color: #19d9cc; }

#mobile_nav.hide {
  transform: translate3d(-100vw, 0, 0); }

#mobile_nav.show {
  transform: translate3d(0vw, 0, 0);
  overflow: hidden; }

.footer {
  border-top: 1px solid #3d3d3d;
  padding-top: 63px;
  padding-bottom: 71px;
  margin-top: 130px; }
  .footer .footer_inner {
    width: 1200px;
    margin: 0 auto; }
  .footer img {
    width: 136px; }
  .footer .company {
    margin-top: 27px;
    font-weight: bold; }
  .footer .desc {
    margin-top: 10px; }
    .footer .desc .address {
      margin-right: 38px; }
    .footer .desc .phone span {
      margin-right: 38px; }
  .footer .copyright {
    margin-top: 40px; }
  @media (max-width: 1200px) {
    .footer {
      border-top: 1px solid #7d7d7d;
      background: rgba(242, 242, 242, 0.9);
      padding-top: 24px;
      padding-bottom: 10px; }
      .footer .footer_inner {
        width: 100%;
        margin: 0;
        padding-left: 30px;
        padding-right: 30px; }
      .footer img {
        width: 112px; }
      .footer .company {
        margin-top: 20px; }
      .footer .desc {
        margin-top: 8px; }
        .footer .desc .address {
          margin-right: 0; }
        .footer .desc .phone {
          display: block;
          margin-top: 20px; }
          .footer .desc .phone span {
            margin-right: 15px; }
        .footer .desc .email {
          display: block;
          margin-top: 6px; }
      .footer .copyright {
        margin-top: 20px; } }

.main .footer {
  margin-top: 0;
  border-top: none; }
  .main .footer .footer_inner {
    width: 1640px; }
  @media (max-width: 1200px) {
    .main .footer .footer_inner {
      width: auto;
      margin: 0; } }

.slick {
  z-index: 0;
  position: relative; }
  .slick .slick-list img {
    width: 100%;
    min-width: 2000px; }
  .slick .desc {
    width: 100%;
    position: absolute;
    top: 406px; }
    .slick .desc .desc-wrap {
      width: 100%;
      max-width: 1640px;
      margin: 0 auto; }
      .slick .desc .desc-wrap .desc-title {
        color: white;
        font-size: 50px;
        font-weight: bold;
        line-height: 54px;
        text-shadow: 3px 3px rgba(0, 0, 0, 0.4); }
      .slick .desc .desc-wrap .desc-subtitle {
        margin-top: 20px;
        font-size: 20px;
        color: white;
        line-height: 30px;
        text-shadow: 1px 1px rgba(0, 0, 0, 0.4); }
      .slick .desc .desc-wrap .desc-control {
        color: white;
        margin-top: 107px;
        display: flex;
        align-items: center; }
        .slick .desc .desc-wrap .desc-control a {
          margin-right: 20px;
          overflow: hidden; }
        .slick .desc .desc-wrap .desc-control img {
          height: 15px; }
        .slick .desc .desc-wrap .desc-control .start, .slick .desc .desc-wrap .desc-control .stop {
          height: 12px; }
        .slick .desc .desc-wrap .desc-control .status-bar {
          width: 180px;
          height: 3px;
          background: white;
          border-radius: 20px;
          margin-right: 20px; }
          .slick .desc .desc-wrap .desc-control .status-bar .status {
            background: #19d9cc;
            height: 3px;
            transition: width 1s; }
          .slick .desc .desc-wrap .desc-control .status-bar .status-1 {
            width: 20%; }
          .slick .desc .desc-wrap .desc-control .status-bar .status-2 {
            width: 40%; }
          .slick .desc .desc-wrap .desc-control .status-bar .status-3 {
            width: 60%; }
          .slick .desc .desc-wrap .desc-control .status-bar .status-4 {
            width: 80%; }
          .slick .desc .desc-wrap .desc-control .status-bar .status-5 {
            width: 100%; }
        .slick .desc .desc-wrap .desc-control .count {
          font-size: 16px;
          text-shadow: 1px 1px rgba(0, 0, 0, 0.4); }
  .slick .desc-bottom {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 169px; }
    .slick .desc-bottom .news {
      background: rgba(58, 119, 170, 0.9);
      color: white;
      height: 169px;
      width: 50%; }
      .slick .desc-bottom .news .news-wrap {
        position: relative;
        max-width: 820px;
        width: 100%;
        margin-left: auto; }
        .slick .desc-bottom .news .news-wrap .title {
          line-height: 30px;
          margin-top: -25px;
          font-size: 20px;
          font-weight: bold; }
        .slick .desc-bottom .news .news-wrap .sub {
          margin-top: 12px;
          line-height: 30px;
          display: flex; }
          .slick .desc-bottom .news .news-wrap .sub .s_title {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            width: 70%; }
          .slick .desc-bottom .news .news-wrap .sub .date {
            margin-left: 27px; }
          .slick .desc-bottom .news .news-wrap .sub span {
            font-size: 16px; }
        .slick .desc-bottom .news .news-wrap .plus {
          position: absolute;
          right: 80px;
          bottom: 0; }
    .slick .desc-bottom .link {
      background: rgba(48, 48, 48, 0.9);
      color: white;
      height: 169px;
      width: 50%; }
      .slick .desc-bottom .link .link-wrap {
        max-width: 820px;
        width: 100%;
        margin-left: 85px;
        height: 143px;
        align-items: flex-end;
        display: flex; }
        .slick .desc-bottom .link .link-wrap .link-item {
          text-align: center;
          line-height: 30px;
          cursor: pointer; }
          .slick .desc-bottom .link .link-wrap .link-item .title {
            font-size: 20px; }
        .slick .desc-bottom .link .link-wrap .link-bar {
          margin-left: 40px;
          margin-right: 40px;
          margin-bottom: 32px; }
          .slick .desc-bottom .link .link-wrap .link-bar img {
            height: 20px; }
  @media (max-width: 1200px) {
    .slick {
      height: 736px;
      width: auto; }
      .slick .slick-list img {
        width: 100%;
        min-width: 0;
        object-fit: cover;
        height: 736px; }
      .slick .desc {
        width: auto;
        position: absolute;
        top: 453px;
        padding: 0 30px; }
        .slick .desc .desc-wrap {
          width: 100%;
          max-width: 100%;
          margin: 0; }
          .slick .desc .desc-wrap .desc-title {
            font-size: 27px;
            line-height: 35px; }
          .slick .desc .desc-wrap .desc-subtitle {
            margin-top: 5px;
            font-size: 17px;
            line-height: 23px; }
          .slick .desc .desc-wrap .desc-control {
            color: white;
            margin-top: 50px;
            display: flex;
            align-items: center; }
            .slick .desc .desc-wrap .desc-control a {
              margin-right: 20px;
              overflow: hidden; }
            .slick .desc .desc-wrap .desc-control img {
              height: 15px; }
            .slick .desc .desc-wrap .desc-control .start, .slick .desc .desc-wrap .desc-control .stop {
              height: 12px; }
            .slick .desc .desc-wrap .desc-control .status-bar {
              width: 84px;
              height: 3px;
              background: white;
              border-radius: 20px;
              margin-right: 10px; }
            .slick .desc .desc-wrap .desc-control .count {
              font-size: 16px; } }

.slick-container {
  min-height: 800px; }
  @media (max-width: 1200px) {
    .slick-container {
      min-height: 0; } }
  .slick-container .slick .desc-bottom {
    display: flex; }
  .slick-container .desc-bottom {
    display: none; }
  @media (max-width: 1200px) {
    .slick-container .slick .desc-bottom {
      display: none; }
    .slick-container .desc-bottom {
      display: flex;
      position: relative;
      flex-flow: column-reverse; }
      .slick-container .desc-bottom .news {
        background: rgba(58, 119, 170, 0.9);
        color: white;
        width: auto;
        padding: 25px 30px; }
        .slick-container .desc-bottom .news .news-wrap {
          position: relative;
          max-width: 820px;
          width: 100%;
          margin-top: 0; }
          .slick-container .desc-bottom .news .news-wrap .title {
            line-height: 30px;
            font-size: 20px;
            font-weight: bold; }
          .slick-container .desc-bottom .news .news-wrap .sub {
            margin-top: 12px;
            line-height: 30px; }
            .slick-container .desc-bottom .news .news-wrap .sub .s_title {
              width: 100%; }
            .slick-container .desc-bottom .news .news-wrap .sub .date {
              display: block;
              margin-top: 13px; }
            .slick-container .desc-bottom .news .news-wrap .sub span {
              font-size: 16px; }
          .slick-container .desc-bottom .news .news-wrap .plus {
            position: absolute;
            right: 0;
            bottom: 0; }
      .slick-container .desc-bottom .link {
        background: rgba(48, 48, 48, 0.9);
        color: white; }
        .slick-container .desc-bottom .link .link-wrap {
          width: 100%; }
          .slick-container .desc-bottom .link .link-wrap .link-item {
            cursor: pointer;
            text-align: center;
            line-height: 30px;
            display: flex;
            padding: 25px 30px;
            align-items: center;
            position: relative; }
            .slick-container .desc-bottom .link .link-wrap .link-item .image {
              width: 55px; }
            .slick-container .desc-bottom .link .link-wrap .link-item .title {
              font-size: 18px;
              margin-left: 21px; }
            .slick-container .desc-bottom .link .link-wrap .link-item .right {
              position: absolute;
              right: 30px; }
            .slick-container .desc-bottom .link .link-wrap .link-item:first-child {
              border-bottom: 1px solid #7d7d7d; } }

.banner {
  width: 100%;
  height: 350px;
  display: flex;
  background-size: cover;
  justify-content: center;
  align-items: center;
  flex-flow: column; }
  .banner .bar {
    width: 25px;
    height: 5px;
    background: white;
    margin-bottom: 7px; }
  .banner .title {
    color: white;
    font-size: 40px;
    font-weight: bold;
    line-height: 53px; }
  @media (max-width: 1200px) {
    .banner {
      height: 230px; }
      .banner .bar {
        margin-top: 110px;
        margin-bottom: 5px; }
      .banner .title {
        font-size: 25px; } }

.popup {
  position: relative;
  overflow: hidden; }
  .popup img {
    max-width: 500px;
    max-height: 700px;
    margin-top: 30px; }
    @media (max-width: 1200px) {
      .popup img {
        max-height: 95vh;
        max-width: 100%; } }
  .popup .close {
    position: absolute;
    top: 5px;
    right: 5px; }
  .popup .check {
    text-align: right;
    padding-right: 8px; }

.ReactModal__Overlay--after-open {
  z-index: 1000; }

.ReactModal__Overlay {
  background-color: rgba(0, 0, 0, 0.3) !important; }

/* pages */
.container {
  position: relative;
  z-index: 1;
  width: 1200px;
  margin: 0 auto; }
  @media (max-width: 1200px) {
    .container {
      width: auto;
      padding-left: 30px;
      padding-right: 30px; } }

.about .tab {
  background: rgba(246, 246, 246, 0.9);
  height: 390px; }
  .about .tab .title {
    margin-bottom: 15px;
    font-size: 27px;
    font-weight: bold; }
  .about .tab .desc {
    margin-bottom: 90px;
    font-size: 16px;
    line-height: 30px;
    color: #808080; }
    .about .tab .desc span {
      font-size: 16px; }

.about .partnership .container {
  display: flex;
  margin-top: 80px; }
  .about .partnership .container .right .item {
    margin-bottom: 45px; }
    .about .partnership .container .right .item .title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 15px; }
    .about .partnership .container .right .item .desc {
      font-size: 16px;
      color: #808080;
      line-height: 30px; }
    .about .partnership .container .right .item:last-child {
      margin-bottom: 0; }

.about .leadership {
  margin-top: 63px; }
  .about .leadership .item-wrap {
    display: flex;
    justify-content: space-between; }
    .about .leadership .item-wrap .item {
      display: block;
      width: 300px;
      margin-bottom: 90px; }
      .about .leadership .item-wrap .item .image {
        width: 100%;
        display: flex;
        height: 322px;
        border-bottom: 1px solid #3d3d3d;
        align-items: flex-end; }
        .about .leadership .item-wrap .item .image img {
          display: block; }
      .about .leadership .item-wrap .item .title {
        font-size: 20px;
        font-weight: bold;
        margin-top: 30px; }
      .about .leadership .item-wrap .item .role {
        color: #808080;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 10px; }
      .about .leadership .item-wrap .item .history {
        color: #808080;
        font-size: 16px;
        line-height: 30px; }
      .about .leadership .item-wrap .item:last-child {
        margin-bottom: 0; }
  .about .leadership .item-wrap-en {
    display: block; }
    .about .leadership .item-wrap-en .item {
      display: flex;
      width: 100%; }
      .about .leadership .item-wrap-en .item .left {
        width: 300px;
        margin-right: 40px; }
      .about .leadership .item-wrap-en .item .history {
        flex: 1;
        height: 322px;
        display: flex;
        align-items: flex-end; }
    @media (max-width: 1200px) {
      .about .leadership .item-wrap-en .item {
        display: block; }
        .about .leadership .item-wrap-en .item .left {
          margin-right: 0;
          width: 100%; }
        .about .leadership .item-wrap-en .item .history {
          height: auto;
          overflow: hidden;
          -webkit-transition: max-height 1s ease;
          -moz-transition: max-height 1s ease;
          -o-transition: max-height 1s ease;
          transition: max-height 1s ease; } }

.about .scboard {
  margin-top: 80px; }
  .about .scboard .item-wrap {
    display: flex;
    justify-content: space-between; }
    .about .scboard .item-wrap .item {
      display: flex;
      width: 50%;
      margin-bottom: 70px; }
      .about .scboard .item-wrap .item .text {
        display: block;
        margin-left: 18px; }
      .about .scboard .item-wrap .item .title {
        font-size: 20px;
        font-weight: bold; }
      .about .scboard .item-wrap .item .role {
        color: #808080;
        font-size: 14px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 10px; }
      .about .scboard .item-wrap .item .history {
        color: #808080;
        font-size: 16px;
        line-height: 30px; }
    .about .scboard .item-wrap:last-child .item {
      width: 100%; }

.readmore-wrap {
  display: flex;
  justify-content: center; }

.readmore {
  width: 112px;
  height: 30px;
  background: #3d3d3d;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  cursor: pointer; }
  .readmore a {
    color: white; }

@media (max-width: 1200px) {
  .about .tab {
    height: auto; }
    .about .tab .title {
      margin-top: 40px;
      margin-bottom: 5px;
      font-size: 21px; }
    .about .tab .desc {
      margin-bottom: 35px; }
  .about .leadership .item-wrap {
    display: block; }
    .about .leadership .item-wrap .item {
      width: 100%;
      margin-bottom: 60px; }
      .about .leadership .item-wrap .item .image {
        justify-content: center; }
  .about .scboard {
    margin-top: 30px; }
    .about .scboard .item-wrap {
      display: block; }
      .about .scboard .item-wrap .item {
        display: block;
        width: 100% !important;
        margin-bottom: 30px; }
        .about .scboard .item-wrap .item .text {
          margin-top: 10px;
          margin-left: 0; }
  .about .partnership .container {
    margin-top: 30px;
    display: block; }
    .about .partnership .container .left {
      display: none; }
    .about .partnership .container .right {
      margin-left: 0; } }

.career {
  position: relative; }
  .career .career-back {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 0;
    background: rgba(246, 246, 246, 0.9);
    height: 231px;
    border-bottom: 1px solid #d1d1d1; }
  .career .career-wrap {
    padding-top: 85px; }
    .career .career-wrap .title {
      font-size: 27px;
      font-weight: bold; }
    .career .career-wrap .career-culture .item-wrap {
      display: flex;
      margin-top: 25px;
      justify-content: space-between; }
      .career .career-wrap .career-culture .item-wrap .item {
        width: 222px;
        height: 170px;
        background: white;
        border: 1px solid #bcbcbc;
        border-radius: 5px;
        padding: 10px 30px 0 26px; }
        .career .career-wrap .career-culture .item-wrap .item .subject {
          font-size: 20px;
          line-height: 54px;
          font-weight: 600; }
        .career .career-wrap .career-culture .item-wrap .item .desc {
          font-size: 16px;
          line-height: 30px;
          color: #808080; }
      @media (max-width: 1200px) {
        .career .career-wrap .career-culture .item-wrap {
          display: block; }
          .career .career-wrap .career-culture .item-wrap .item {
            width: auto;
            margin-bottom: 20px;
            height: auto;
            padding-bottom: 25px; }
            .career .career-wrap .career-culture .item-wrap .item:last-child {
              margin-bottom: 0; } }
    .career .career-wrap .career-welfare {
      margin-top: 58px; }
      .career .career-wrap .career-welfare .item-wrap {
        display: flex;
        margin-top: 15px;
        justify-content: space-between; }
        .career .career-wrap .career-welfare .item-wrap .item {
          width: 280px; }
          .career .career-wrap .career-welfare .item-wrap .item .subject {
            font-size: 20px;
            line-height: 30px;
            font-weight: 600; }
          .career .career-wrap .career-welfare .item-wrap .item img {
            margin-top: 30px; }
          .career .career-wrap .career-welfare .item-wrap .item ul {
            margin-top: 30px;
            font-size: 16px;
            line-height: 30px;
            color: #808080; }
            .career .career-wrap .career-welfare .item-wrap .item ul li {
              list-style: inside; }
              .career .career-wrap .career-welfare .item-wrap .item ul li span {
                position: relative;
                left: -10px; }
        @media (max-width: 1200px) {
          .career .career-wrap .career-welfare .item-wrap .item {
            width: 45%;
            margin-bottom: 20px; }
            .career .career-wrap .career-welfare .item-wrap .item:last-child {
              margin-bottom: 0; }
          .career .career-wrap .career-welfare .item-wrap:last-child {
            margin-top: 40px; } }

.contact {
  margin-top: 86px; }
  .contact .contact-item {
    margin-top: 24px; }
    .contact .contact-item .title {
      font-size: 25px;
      font-weight: 500; }
    .contact .contact-item .address {
      margin-top: 11px;
      font-size: 18px;
      margin-bottom: 10px; }
      @media (max-width: 1200px) {
        .contact .contact-item .address {
          line-height: 25px; } }
    .contact .contact-item .desc {
      font-size: 16px;
      line-height: 25px; }

.tab {
  height: 125px;
  border-bottom: 1px solid #d1d1d1;
  display: flex;
  align-items: flex-end; }
  .tab ul {
    display: flex; }
    .tab ul li {
      margin-right: 45px; }
    .tab ul a {
      font-size: 20px;
      display: block;
      overflow: hidden;
      height: 35px; }
    .tab ul .active a {
      font-weight: bold;
      border-bottom: 3px solid #3d3d3d; }

@media (max-width: 1200px) {
  .tab ul {
    display: -webkit-inline-box;
    overflow: auto;
    margin-right: -30px; }
    .tab ul::-webkit-scrollbar {
      display: none; }
    .tab ul li {
      margin-right: 30px; }
    .tab ul a {
      font-size: 17px; }
    .tab ul .active a {
      font-weight: bold;
      border-bottom: 3px solid #3d3d3d; } }

.news .news-wrap {
  margin-top: 70px; }
  .news .news-wrap .news-item {
    height: 80px;
    margin-bottom: 20px;
    border: 1px solid #bcbcbc;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
    cursor: pointer;
    transition: all 0.3s; }
    .news .news-wrap .news-item .title {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 90%;
      font-size: 18px; }
      @media (max-width: 1200px) {
        .news .news-wrap .news-item .title {
          overflow: auto;
          white-space: pre-line; } }
    .news .news-wrap .news-item .date {
      font-size: 17px; }
    .news .news-wrap .news-item:hover, .news .news-wrap .news-item.active {
      border: 1px solid #3d3d3d;
      background: #F6F6F6; }
    .news .news-wrap .news-item.active {
      margin-bottom: 0; }
    @media (max-width: 1200px) {
      .news .news-wrap .news-item {
        flex-flow: column;
        justify-content: center;
        align-items: flex-start;
        height: auto;
        line-height: 25px;
        padding: 10px 25px; }
        .news .news-wrap .news-item .date {
          margin-top: 10px;
          font-size: 15px; } }

.news .news-detail {
  padding: 60px 20px 75px 20px; }
  .news .news-detail div, .news .news-detail span {
    font-size: 16px;
    color: #808080;
    line-height: 24px;
    word-break: break-all;
    white-space: pre-line; }
  .news .news-detail a {
    text-decoration: underline; }
  .news .news-detail img {
    max-width: 100%; }

.news-detail-wrap .news-item .title {
  white-space: pre-wrap !important; }

.news-toList {
  margin-bottom: 15px; }
  .news-toList a {
    cursor: pointer;
    display: flex;
    align-items: center; }
    .news-toList a img {
      margin-right: 5px; }

.pipeline-container {
  margin-top: 85px; }
  .pipeline-container .subject {
    font-size: 27px;
    font-weight: bold;
    line-height: 54px; }
  .pipeline-container .pipeline-item {
    display: flex;
    padding: 35px;
    border: 1px solid #bcbcbc;
    border-radius: 5px;
    margin-bottom: 20px; }
    .pipeline-container .pipeline-item .pipeline-text {
      width: 250px;
      margin-right: 10px; }
      .pipeline-container .pipeline-item .pipeline-text .pipeline-title {
        font-size: 33px;
        font-weight: bold;
        color: #235d95;
        margin-bottom: 10px; }
      .pipeline-container .pipeline-item .pipeline-text .pipeline-desc {
        font-size: 15px;
        line-height: 24px;
        overflow: hidden; }
    .pipeline-container .pipeline-item .pipeline-progress {
      padding-left: 40px;
      padding-right: 40px;
      border-left: 1px solid #c2c2c2;
      border-right: 1px solid #c2c2c2; }
      .pipeline-container .pipeline-item .pipeline-progress .pipeline-step {
        margin-bottom: 23px; }
      .pipeline-container .pipeline-item .pipeline-progress .progress-text-pp {
        display: flex;
        position: relative; }
        .pipeline-container .pipeline-item .pipeline-progress .progress-text-pp .w {
          position: absolute;
          left: 28%; }
          @media (max-width: 1200px) {
            .pipeline-container .pipeline-item .pipeline-progress .progress-text-pp .w {
              width: 50px; } }
        .pipeline-container .pipeline-item .pipeline-progress .progress-text-pp .e {
          position: absolute;
          left: 55%; }
        .pipeline-container .pipeline-item .pipeline-progress .progress-text-pp .r {
          position: absolute;
          left: 68%; }
        .pipeline-container .pipeline-item .pipeline-progress .progress-text-pp .a {
          position: absolute;
          left: 80%; }
        .pipeline-container .pipeline-item .pipeline-progress .progress-text-pp .s {
          position: absolute;
          right: 0; }
    .pipeline-container .pipeline-item .pipeline-ownership {
      padding-left: 30px; }
      .pipeline-container .pipeline-item .pipeline-ownership .pipeline-o {
        margin-bottom: 16px; }
      .pipeline-container .pipeline-item .pipeline-ownership .pipeline-img {
        display: flex;
        align-items: center; }
        .pipeline-container .pipeline-item .pipeline-ownership .pipeline-img img {
          max-width: 130px;
          height: 100%;
          max-height: 53px; }
    @media (max-width: 1200px) {
      .pipeline-container .pipeline-item {
        display: block;
        padding: 20px; }
        .pipeline-container .pipeline-item .pipeline-progress {
          padding-left: 0;
          padding-right: 0;
          padding-top: 20px;
          padding-bottom: 20px;
          margin-top: 20px;
          margin-bottom: 20px;
          border-left: none;
          border-right: none;
          border-top: 1px solid #c2c2c2;
          border-bottom: 1px solid #c2c2c2; }
        .pipeline-container .pipeline-item .pipeline-ownership {
          padding-left: 0;
          padding-right: 0; } }
  .pipeline-container .pipeline-top {
    padding-top: 10px;
    padding-bottom: 10px; }
    .pipeline-container .pipeline-top .title {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 17px; }
    .pipeline-container .pipeline-top .desc {
      font-size: 16px;
      line-height: 30px;
      color: #808080;
      white-space: pre-line;
      word-break: break-all; }
  .pipeline-container .pipeline-novel {
    margin-top: 105px; }
    .pipeline-container .pipeline-novel .pipeline-item .pipeline-text {
      display: flex;
      align-items: center; }
      .pipeline-container .pipeline-novel .pipeline-item .pipeline-text .pipeline-title {
        margin: 0;
        font-size: 20px;
        line-height: 27px; }
  .pipeline-container .pipeline-bottom {
    margin-top: 10px; }
    .pipeline-container .pipeline-bottom .desc {
      font-size: 16px;
      line-height: 30px;
      color: #808080;
      white-space: pre-line;
      word-break: break-all; }

.progress-bar {
  width: 438px;
  height: 7px;
  position: relative;
  background-color: #cbcbcb;
  margin-bottom: 20px; }
  .progress-bar::before {
    position: absolute;
    content: url("/static/images/circle_active.svg");
    left: -3px;
    top: -3.2px;
    z-index: 1; }
  .progress-bar::after {
    position: absolute;
    content: url("/static/images/circle_grey.svg");
    top: -3.2px;
    right: -3px;
    z-index: 1; }
  .progress-bar .progress {
    height: 7px;
    position: relative;
    background-color: #32a0a8;
    -webkit-transition: width 1s ease;
    -moz-transition: width 1s ease;
    -o-transition: width 1s ease;
    transition: width 1s ease;
    width: 2%; }
    .progress-bar .progress::after {
      position: absolute;
      content: url("/static/images/circle_active.svg");
      top: -3.2px;
      right: -3px;
      z-index: 3; }
  .progress-bar .progress-zero {
    height: 7px;
    position: relative;
    background-color: #32a0a8; }
  .progress-bar .white {
    width: 3px;
    height: 7px;
    position: absolute;
    background-color: white;
    z-index: 2; }
  .progress-bar .white-1 {
    left: 33.3%; }
  .progress-bar .white-2 {
    left: 66.7%; }
  @media (max-width: 1200px) {
    .progress-bar {
      width: 100%; } }

.progress-split {
  width: 490px; }
  .progress-split .white-1 {
    left: 24%; }
  .progress-split .white-2 {
    left: 50.1%; }
  .progress-split .white-3 {
    left: 62.6%; }
  .progress-split .white-4 {
    left: 75.1%; }
  .progress-split .white-5 {
    left: 87.5%; }
  @media (max-width: 1200px) {
    .progress-split {
      width: 100%; } }

.pipeline-novel .progress-text {
  display: flex;
  position: relative;
  justify-content: space-between; }
  .pipeline-novel .progress-text .w {
    margin: 0 !important; }
  .pipeline-novel .progress-text .e {
    margin: 0 !important; }

.science .intro {
  background: #F6F6F6;
  padding: 85px 0;
  border-bottom: 1px solid #d1d1d1; }
  .science .intro .title {
    font-size: 27px;
    font-weight: bold;
    margin-bottom: 15px; }
  .science .intro .desc {
    font-size: 16px;
    line-height: 30px;
    color: #808080;
    margin-bottom: 50px; }

.science .tech {
  padding-top: 60px;
  padding-bottom: 120px;
  border-bottom: 1px solid #d1d1d1; }
  .science .tech .item {
    display: flex;
    margin-top: 60px; }
    .science .tech .item .image {
      margin-right: 47px; }
    .science .tech .item .title {
      font-size: 20px;
      font-weight: bold; }
    .science .tech .item .desc {
      color: #808080;
      font-size: 16px;
      line-height: 30px;
      margin-top: 15px; }

.science .publication {
  margin-top: 100px; }
  .science .publication .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px; }
  .science .publication .item-wrap {
    margin-top: 30px;
    display: flex; }
    .science .publication .item-wrap .item {
      width: 303px;
      padding: 25px 35px;
      border: 1px solid #bcbcbc;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s;
      margin-right: 30px; }
      .science .publication .item-wrap .item:last-child {
        margin-right: 0; }
      .science .publication .item-wrap .item .ko {
        font-size: 16px;
        line-height: 30px;
        color: #808080;
        margin-bottom: 15px; }
      .science .publication .item-wrap .item .en {
        font-size: 16px;
        line-height: 25px;
        color: #808080; }
      .science .publication .item-wrap .item .url {
        margin-top: 35px;
        display: flex;
        justify-content: flex-end; }
      .science .publication .item-wrap .item:hover {
        border: 1px solid #3d3d3d;
        background: #F6F6F6; }
        .science .publication .item-wrap .item:hover div {
          color: #3d3d3d; }

@media (max-width: 1200px) {
  .science .intro {
    padding-top: 60px; }
  .science .tech .item {
    display: block; }
    .science .tech .item .image {
      margin-right: 0;
      text-align: center; }
    .science .tech .item .title {
      margin-top: 10px;
      text-align: center; }
    .science .tech .item:first-child {
      margin-top: 0; }
  .science .publication .title {
    margin-bottom: 30px; }
  .science .publication .item-wrap {
    display: block;
    margin-top: 0; }
    .science .publication .item-wrap .item {
      margin-right: 0;
      width: auto;
      margin-bottom: 20px;
      padding: 20px; } }
